<template>
  <div
    class="flex items-center gap-4 justify-between rounded-md cursor-pointer"
  >
    <span class="text-[14px]" @click="update">Saldo: {{ cost }} <fa v-if="updating" class="text-[12px]" icon="fa-rotate-right" spin /></span>
    <button
      class="h-[30px] whitespace-nowrap bg-yellow-400 font-bold text-[10px] uppercase rounded-md flex items-center px-4"
      @click="add"
    >
      Adicionar crédito
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updating: false,
    };
  },
  computed: {
    cost() {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.$store.state.me.credits || 0);
    },
  },
  methods: {
    update() {
      this.updating = true;
      this.$axios.get("/me").then((res) => {
        this.$store.commit("setMe", res.data);
        this.updating = false
      });
      this.$forceUpdate();
    },
    add() {
      this.$router.push({ name: "checkout" });
    },
  },
};
</script>

<style></style>
