<template>
  <div class="flex justify-between items-center bg-white shadow-sm mb-4 p-4 border-b">
    <h3 class="flex justify-between items-center">
      <logo-component></logo-component>
    </h3>    
    <div v-if="show_menu == true" class="flex flex-col lg:flex-row justify-between mb-auto gap-4 absolute left-0 w-full lg:h-auto p-4 z-[9999] bg-white shadow-sm top-[74px]">
      <profile-component></profile-component>
      <credits-component></credits-component>
    </div>
    <fa icon="fa-bars" @click="show_menu=!show_menu" class="text-[24px] border p-6 cursor-pointer absolute top-0 right-0 z-50" />
  </div>
</template>

<script>
import { version } from "../../package";
import LoginComponent from "@/components/LoginComponent.vue";
import SettingsComponent from "@/components/SettingsComponent.vue";
import LogoComponent from "@/components/LogoComponent.vue";
import DownloadComponent from "@/components/DownloadComponent.vue";
import CreditsComponent from "@/components/CreditsComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import ProgressComponent from "@/components/ProgressComponent.vue";
import TutorialComponent from "@/components/TutorialComponent.vue";
import SitesComponent from '@/components/SitesComponent.vue';

export default {
  name: "SidebarComponent",
  components: {
    LoginComponent,
    SettingsComponent,
    LogoComponent,
    DownloadComponent,
    CreditsComponent,
    ProfileComponent,
    ProgressComponent,
    TutorialComponent,
    SitesComponent,
  },
  data() {
    return {
      show_menu: false,
      version,
    };
  },
  watch: {
    '$route': {
      deep: true,
      handler() {
        this.show_menu = false;
      },
    },
  },
  mounted() {
    this.show_menu = false;
  },
};
</script>

<style></style>
