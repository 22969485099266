<template>
  <div class="site-info" v-if="site.url">
    <div class="flex gap-4 w-full items-center justify-between mr-auto">
      <small
      class="status"
      :style="{ borderColor: site.color }"
      :class="{
        [`border-l-4`]: true,
        active: currentSite == site.url,
        token: site.token.length > 50,
      }"
    >
      {{ site.url }}</small
    >
      <input
        class="rounded-0"
        type="color"
        v-model="site.color"
        @change="changecolor($event)"
        id=""
      />
    </div>
    <div class="flex gap-4 w-full lg:w-auto items-center justify-between mr-auto">
      <span class="rem py-1 rounded-md w-full flex items-center justify-center px-4 bg-slate-300" @click="removeSite(site.url)">x</span>
      <span
        class="connect py-2 rounded-md w-full flex items-center justify-center px-4 bg-slate-300"
        :class="{ active: site.token.length > 200 }"
        @click="connect(site.url)"
        ><font-awesome-icon icon="fa-solid fa-plug"
      /></span>
      <a :href="site.url" class="access py-2 rounded-md w-full flex items-center justify-center px-4 bg-slate-300" target="_blank"
        ><font-awesome-icon icon="fa-solid fa-external-link"
      /></a>
      <a
        :href="`${site.url}/wp-admin/admin-ajax.php?action=iaxpress_autologin&token=${site.token}&token_key=${site.token_key}`"
        class="access py-2 rounded-md w-full flex items-center justify-center px-4 bg-slate-300"
        target="_blank"
        ><font-awesome-icon icon="fa-brands fa-wordpress-simple"
      /></a>
    </div>
  </div>
</template>

<script>
import SitePostsCount from "./SitePostsCount.vue";
export default {
  components: { SitePostsCount },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  methods: {
    changecolor(event) {
      this.site.color = event.target.value;
      this.$axios.post(`/sites/${this.site._id.$oid}/color`, {
        color: this.site.color,
      });
    },
    connect() {
      localStorage.connecting = this.site.url;
      location.href = `${this.site.url}/wp-admin/admin-ajax.php?action=iaxpress_generate_token&user_id=${this.me._id.$oid}`;
    },
    removeSite() {
      this.$axios.delete(`/sites/${this.site._id.$oid}`).then(() => {
        this.$emit("remove", this.site);
        this.site.url = false;
      });
    },
  },
};
</script>

<style scoped>
.site-info {
  @apply relative flex flex-col lg:flex-row items-center gap-2 w-full justify-between m-0 whitespace-nowrap text-ellipsis max-w-full;
  input {
    @apply mr-2 cursor-pointer;
  }
  .status {
    @apply bg-gray-200 w-full px-2 py-1 cursor-pointer text-[14px];
    &.active {
      @apply bg-green-400;
    }
  }
  .rem {
    @apply cursor-pointer;
  }
  .connect {
    @apply cursor-pointer;
    &.active {
      @apply text-green-700;
    }
  }
  &.posted:before {
    content: "";
    @apply absolute z-10 block w-[8px] h-[8px] left-[-10px] rounded-lg bg-yellow-400;
  }
}
</style>
