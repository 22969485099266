<template>
  <div class="cols p-4 flex-col-reverse lg:flex-row">
    <div class="col-left mb-auto">
      <div class="flex mb-4 flex-col lg:flex-row gap-4">
        <input
          class="form-control !mb-0 !w-full"
          v-model="form.fonte"
          type="text"
          @keyup="syncEdit"
          placeholder="Fonte"
        />
        <button
          @click="importPost"
          class="flex gap-2 justify-center items-center text-[1.3em] bg-purple-600 rounded-md text-white h-[40px] px-6"
        >
          <span class="text-[11px] leading-[11px] uppercase whitespace-nowrap">
            {{ loading ? 'Extraindo dados, aguarde...' : 'Extrair dados da fonte' }}
          </span>
          <font-awesome-icon icon="fa-solid fa-file-import" />
        </button>
      </div>
      <div class="title-container gap-4">
        <input
          class="form-control"
          v-model="form.title"
          type="text"
          placeholder="Título ou prompt, exemplo: Gere um títuo com SEO otimizado sobre Como escrever um post sobre IA"
          @blur="calculateCost"
          @keyup="syncEdit"
        />
      </div>
      <div class="mb-4 w-full">
        <ckeditor
          class="w-full"
          v-if="editor && config"
          placeholder="Texto ou prompt, exemplo: Gere um títuo com SEO otimizado sobre Como escrever um post sobre IA"
          @blur="calculateCost"
          @input="monitorCost"
          v-model="form.content"
          :modelValue="form.content"
          :editor="editor"
          :config="config"
        />
      </div>
      <credits-component
        v-if="!me.credits || me.credits <= 0"
      ></credits-component>
      <div
        class="w-full flex gap-4 flex-col lg:flex-row items-center justify-end"
      >
        <button
          vif="me.credits > 0"
          @click="save"
          type="button"
          class="bg-green-500 w-full lg:w-auto h-10 py-1 px-6 mt-0 text-white p-0 uppercase text-[14px] font-bold rounded-md"
        >
         {{ publishing ? 'Agaurde...' :  'Publicar texto' }}
        </button>
      </div>
    </div>
    <div class="col-right mb-auto sticky top-0">
      <label class="mb-2 block font-bold">Custo estimado para gerar com IA</label>
      <div class="flex gap-4">

        <span
          class="flex w-full items-center justify-center mb-4 px-6 rounded-md h-[40px] font-bold cursor-pointer bg-yellow-400"
          >
            <span class="whitespace-nowrap">{{ cost }}</span>
          </span>
          <button
          :disabled="updatingCost"
          type="button"
          @click="calculateCost"
          class="flex items-center justify-center mb-4 px-6 rounded-md h-[40px] font-bold cursor-pointer bg-green-600 text-white"
          >
          <span class="whitespace-nowrap">{{ updatingCost ? 'Atualizando custo...' : 'Atualizar custo' }}</span>
        </button>
      </div>
        <button
          vif="me.credits > 0"
          :disabled="generating"
          @click="generateIA"
          type="button"
          class="bg-blue-500 mb-4 w-full lg:w-auto h-10 mt-0 text-white px-4 uppercase text-[14px] font-bold rounded-md"
        >
        <font-awesome-icon icon="fa-solid fa-robot" class="text-[20px] mr-2" /> {{ generating ? 'Gerando, aguarde...' : 'Gerar post com IA' }}
        </button>
      <div class="image">
        <label class="mb-2 block font-bold">Imagem do post</label>
        <label
          class="selector"
          :style="{
            backgroundImage: `url('${image}')`,
          }"
        >
          <input class="file" type="file" accept="image/*" @change="upload" />
          <font-awesome-icon icon="fa-solid fa-camera" />
        </label>
        <input
          type="text"
          class="form-control mt-3"
          v-model="form.caption"
          placeholder="Legenda da Imagem"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";
import {
  ClassicEditor,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  BlockToolbar,
  Bold,
  CloudServices,
  Code,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Markdown,
  MediaEmbed,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromMarkdownExperimental,
  PasteFromOffice,
  RemoveFormat,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Base64UploadAdapter,
} from "ckeditor5";
import { Ckeditor } from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import translations from "ckeditor5/translations/pt-br.js";

export default {
  name: "WriteView",
  components: {
    ckeditor: Ckeditor,
  },
  data() {
    return {
      steps: [
        {
          attachTo: { element: "#sync" },
          content: { title: "Sincronize seus dados entre os dispositivos" },
        },
      ],
      windowWidth: window.innerWidth,
      blur: false,
      view: 1,
      version,
      currentPost: false,
      uselist: {},
      image: false,
      fonte: "",
      sites: {},
      posts: [],
      usarOriginal: false,
      auto: false,
      form: {
        cost: 0,
        post_id: 0,
        title: "",
        content: "",
        fonte: "",
        caption: "",
        categories: []
      },
      currentSite: false,
      activeSite: false,
      date: new Date().toLocaleDateString(),
      loading: false,
      calculating: false,
      updatingCost: false,
      generating: false,
      publishing: false,
    };
  },
  watch: {
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }

    document.onpaste = (event) => {
      var items = (
        event.clipboardData ||
        event.originalEvent.clipboardData ||
        window.clipboardData
      ).items;

      for (let index in items) {
        var item = items[index];
        if (item.kind === "file") {
          event.preventDefault();
          var blob = item.getAsFile();
          var reader = new FileReader();
          reader.onload = (event) => {
            this.image = event.target.result;
            for (let i in this.posts) {
              if (this.currentPost.id != this.posts[i].id) {
                continue;
              }
              this.posts[i].replace_image = event.target.result;
            }
          };
          reader.readAsDataURL(blob);
          return;
        }
      }
    };
    window.addEventListener(
      "resize",
      () => (this.windowWidth = window.innerWidth)
    );
  },
  computed: {
    editor() {
      return ClassicEditor;
    },
    config() {
      return {
        removePlugins: ["Markdown"],
        placeholder: "Texto ou prompt, exemplo: Gere um artigo com SEO otimizado sobre Como escrever um post sobre IA",
        height: 400,
        licenseKey: "GPL", // Or 'GPL'.
        toolbar: {
          items: [
            "heading",
            "style",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "link",
            "insertTable",
            "highlight",
            "blockQuote",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: false,
        },
        plugins: [
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          BlockToolbar,
          Bold,
          CloudServices,
          Code,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HorizontalLine,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Base64UploadAdapter,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Markdown,
          MediaEmbed,
          Mention,
          PageBreak,
          Paragraph,
          PasteFromMarkdownExperimental,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Style,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          Underline,
        ],
        balloonToolbar: [
          "bold",
          "italic",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
        ],
        blockToolbar: [
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
        ],
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true,
            },
          ],
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
          ],
        },
        initialData:
          '<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n\tYou\'ve successfully created a CKEditor 5 project. This powerful text editor\n\twill enhance your application, enabling rich text editing capabilities that\n\tare customizable and easy to use.\n</p>\n<h3>What\'s next?</h3>\n<ol>\n\t<li>\n\t\t<strong>Integrate into your app</strong>: time to bring the editing into\n\t\tyour application. Take the code you created and add to your application.\n\t</li>\n\t<li>\n\t\t<strong>Explore features:</strong> Experiment with different plugins and\n\t\ttoolbar options to discover what works best for your needs.\n\t</li>\n\t<li>\n\t\t<strong>Customize your editor:</strong> Tailor the editor\'s\n\t\tconfiguration to match your application\'s style and requirements. Or\n\t\teven write your plugin!\n\t</li>\n</ol>\n<p>\n\tKeep experimenting, and don\'t hesitate to push the boundaries of what you\n\tcan achieve with CKEditor 5. Your feedback is invaluable to us as we strive\n\tto improve and evolve. Happy editing!\n</p>\n<h3>Helpful resources</h3>\n<ul>\n\t<li>📝 <a href="https://portal.ckeditor.com/checkout?plan=free">Trial sign up</a>,</li>\n\t<li>📕 <a href="https://ckeditor.com/docs/ckeditor5/latest/installation/index.html">Documentation</a>,</li>\n\t<li>⭐️ <a href="https://github.com/ckeditor/ckeditor5">GitHub</a> (star us if you can!),</li>\n\t<li>🏠 <a href="https://ckeditor.com">CKEditor Homepage</a>,</li>\n\t<li>🧑‍💻 <a href="https://ckeditor.com/ckeditor-5/demo/">CKEditor 5 Demos</a>,</li>\n</ul>\n<h3>Need help?</h3>\n<p>\n\tSee this text, but the editor is not starting up? Check the browser\'s\n\tconsole for clues and guidance. It may be related to an incorrect license\n\tkey if you use premium features or another feature-related requirement. If\n\tyou cannot make it work, file a GitHub issue, and we will help as soon as\n\tpossible!\n</p>\n',
        language: "pt-br",
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        mention: {
          feeds: [
            {
              marker: "@",
              feed: [
                /* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
              ],
            },
          ],
        },
        menuBar: {
          isVisible: true,
        },
        style: {
          definitions: [
            {
              name: "Article category",
              element: "h3",
              classes: ["category"],
            },
            {
              name: "Title",
              element: "h2",
              classes: ["document-title"],
            },
            {
              name: "Subtitle",
              element: "h3",
              classes: ["document-subtitle"],
            },
            {
              name: "Info box",
              element: "p",
              classes: ["info-box"],
            },
            {
              name: "Side quote",
              element: "blockquote",
              classes: ["side-quote"],
            },
            {
              name: "Marker",
              element: "span",
              classes: ["marker"],
            },
            {
              name: "Spoiler",
              element: "span",
              classes: ["spoiler"],
            },
            {
              name: "Code (dark)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-dark"],
            },
            {
              name: "Code (bright)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-bright"],
            },
          ],
        },
        le: {
          contentToolbar: [
            "leColumn",
            "leRow",
            "mergeTableCells",
            "leProperties",
            "leCellProperties",
          ],
        },
        translations: [translations],
      };
    },
    isMobile() {
      return this.windowWidth < 800;
    },
    configs() {
      delete this.$store.state.configs.sites
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    cost() {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.form.cost || 0);
    },
    gtag() {
      return window.gtag;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    proxyUrl() {
      return process.env.VUE_APP_PROXY_URL;
    },
  },
  methods: {
    monitorCost() {
      if (this.calculating) {
        clearTimeout(this.calculating);
      }
      this.calculating = setTimeout(() => {
        this.calculateCost();
      }, 3000);
    },
    syncEdit() {
      for (let i in this.posts) {
        if (this.currentPost.id != this.posts[i].id) {
          continue;
        }
        if (this.form.title) {
          this.posts[i].replace_title = this.form.title;
        }
        if (this.form.content) {
          this.posts[i].replace_content = this.form.content;
        }
        if (this.form.fonte) {
          this.posts[i].replace_link = this.form.content;
        }
        this.currentPost = this.posts[i];
      }
    },
    calculateCost() {
      if(this.form.title || this.form.content) {
        this.updatingCost = true;
        return this.$axios
          .post("/tokens", {
            title: this.form.title,
            content: this.form.content,
          })
          .then((res) => {
            this.updatingCost = false;
            this.form.cost = res.data.total_cost;
          }).catch(() => {
            this.updatingCost = false;
            this.$toast.error('Não foi possível calcular o custo do post.');
          });
      }
    },
    importPost() {
      this.loading = true;
      this.$axios
        .post(`${this.proxyUrl}/content`, { url: this.form.fonte })
        .then((res) => {
          let post = res.data;
          this.currentPost = post;
          this.image = post.better_featured_image?.source_url;
          if(post._embedded && post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0]) {
            this.image = post._embedded["wp:featuredmedia"][0].source_url;
          }
          this.form.title = post.title.rendered;
          this.form.content = post.content.rendered;
          this.loading = false;
          this.calculateCost();
        }).catch(() => {
          this.loading = false;
          this.$toast.error('Não foi possível extrair os dados da fonte.');
        });
    },
    upload(e) {
      let [file] = e.target.files;
      if (file) {
        var reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target.result;
          this.gtag("event", "upload", { site: this.currentSite });
        }; // data url!
        reader.readAsDataURL(file);
      }
    },
    generateIA() {
      if(!this.form.title && !this.form.content) {
        this.$toast.error('Você precisa preencher pelo menos o título do post.');
        return;
      }
      this.generating = true;
      this.$axios
        .post("ia", { title: this.form.title, content: this.form.content })
        .then((res) => {
          if (res.data.title) {
            this.form.title = res.data.title;
          }
          if (res.data.content) {
            this.form.content = res.data.content;
          }
          this.calculateCost()
          this.$axios.get("/me").then((res) => {
            this.$store.commit("setMe", res.data);
          });
          this.$forceUpdate();
          this.generating = false;
        })
        .catch(() => {
          this.generating = false;
        });
    },
    async save() {
      this.form.image = this.image;
      this.form.url = this.fonte || '';
      if(!this.form.title && !this.form.content) {
        this.$toast.error('Você precisa preencher pelo menos o título do post.');
        return;
      }
      this.publishing = true;
      this.$axios.post("queue", this.form)
        .then(() => {
          this.publishing = false;
          this.$router.push({name: 'postar', query: {single: 1}});
        })
        .catch(() => {
          this.publishing = false;
        });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  font-family: "Lato";
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  font-family: "Lato";
  line-height: 1.6;
  word-break: break-word;
  min-height: 40vh;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 795px;
  max-width: 795px;
}

.editor-container_include-block-toolbar {
  margin-left: 42px;
}

.ck-content h3.category {
  font-family: "Oswald";
  font-size: 20px;
  font-weight: bold;
  color: #555;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck-content h2.document-title {
  font-family: "Oswald";
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border: 0;
}

.ck-content h3.document-subtitle {
  font-family: "Oswald";
  font-size: 20px;
  color: #555;
  margin: 0 0 1em;
  font-weight: bold;
  padding: 0;
}

.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(
      135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)
    ),
    linear-gradient(
      135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color)
    );
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
  font-family: "Oswald";
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
  content: "“";
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck-content span.marker {
  background: yellow;
}

.ck-content span.spoiler {
  background: #000;
  color: #000;
}

.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}

.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck-content pre.fancy-code::before {
  content: "";
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}
</style>
