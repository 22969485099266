<template>
    <div v-if="!isMobile || (isMobile && view == 1)" class="sites">
      <div class="flex flex-col gap-4 justify-between w-full">
        <h1 class="text-2xl">Sites</h1>        
        <div class="flex gap-4 justify-start mb-4 w-full">
          <button class="btn primary btn-small" @click="$router.push({name: 'add-site'})" type="button">
            <span class="px-2">adicionar site</span>
          </button>
        </div>
      </div>
      <template v-for="site in sites" :key="site.url">
        <site-component :site="site" />
        <site-posts-count :site="site" />
      </template>
    </div>
</template>

<script>
import SitesService from '@/services/SitesService'
import SiteComponent from './SiteComponent.vue';
export default {
  name: "SitesComponent",
  components: { SiteComponent },
  data() {
    return {
      sites: []
    }
  },
  watch: {},
  computed: {
    gtag() {
      return window.gtag;
    },
    isMobile() {
      return this.windowWidth < 800;
    },
  },
  mounted() {
    SitesService.read().then((res) => {
      this.sites = res.data;
    });
  },
  methods: {
    exportsites() {
      const jsonString = JSON.stringify(this.sites, null, 2); // The null and 2 are for formatting the JSON
      const blob = new Blob([jsonString], { type: "application/json" });
      const downloadLink = document.createElement("a");
      downloadLink.download = `iaxpress-${new Date()
        .toLocaleString()
        .replace(/([^\d]+)/gi, "")}.json`; // Filename for the downloaded file
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.click();
      this.gtag("event", "export_sites");
    },
    importsites() {
      var input = document.createElement("input");
      input.type = "file";
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (!file) {
          console.log("No file selected");
          return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
          const contents = event.target.result;
          try {
            const jsonData = JSON.parse(contents);
            for (let prop in jsonData) {
              this[prop] = jsonData[prop];
            }
            // this.$store.commit("setConfigs", jsonData);
            setTimeout(this.saveData, 2000);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        };
        reader.readAsText(file);
      };
      input.accept = ".json";
      input.click();
      this.gtag("event", "import_sites");
    },
  },
};
</script>

<style></style>
