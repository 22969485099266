<template>
  <div class="cols p-4">
    <div class="bg-white flex flex-col rounded-lg justify-center items-center w-full p-4">
      <h1 class="text-2xl font-bold text-center">Adicionar novo site</h1>
        <p class="text-red-500 text-center" v-if="error">
          {{ error }}
        </p>
      <div class="flex w-full justify-center items-center mt-4">
        <input
          type="text"
          v-model="url_new_site"
          placeholder="URL"
          class="py-2 px-4 border w-full max-w-md border-gray-300 rounded-lg"
        />
        <button
          :disabled="!validUrl(url_new_site)"
          @click="addSite"
          class="py-2 disabled:opacity-50 disabled:cursor-not-allowed px-4 uppercase bg-blue-500 text-white rounded-lg ml-2"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";

export default {
  name: "AddSiteView",
  components: {},
  data() {
    return {
      url_new_site: "",
      error: "",
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }
  },
  methods: {
    validUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    addSite() {
      let url = this.url_new_site;
      url = url.replace(/\/+$/, '');
      this.$axios.get(`https://proxy.iaxpress.app?url=${url}/wp-json`)
        .then((res) => {
          if (res.data && res.data.namespaces && res.data.namespaces.includes("wp/v2")) {
            // It's a WordPress site
            localStorage.connecting = url;
            let action_url = `${url}/wp-admin/admin-ajax.php?action=iaxpress_generate_token&user_id=${this.me._id.$oid}`;
            this.$axios.get(`https://proxy.iaxpress.app?url=${action_url}`)
              .then((res) => {
                if (res.data.trim() == "0") {
                  throw new Error("Plugin não instalado");
                } else {
                  location.href = action_url
                }
              })
              .catch((error) => {
                this.error = error.message;
              });
          } else {
            throw new Error("Não é um site WordPress");
          }
        })
        .catch((error) => {
          if(error.message.match(/Unexpected token/)) {
            this.error = 'Houve um erro ao integrar o site. Tente novamente mais tarde.';
            return;
          }
          this.error = error.message;
        });
    },
    connect(site) {
      localStorage.connecting = site;
      location.href = `${site}wp-admin/admin-ajax.php?action=iaxpress_generate_token&user_id=${this.me._id.$oid}`;
    },
  },
};
</script>
