<template>
  <div class="cols p-4">
    <div class="bg-white flex flex-col rounded-lg justify-center items-center w-full">
      <sites-component></sites-component>
    </div>
  </div>
</template>

<script>
import SitesComponent from '@/components/SitesComponent.vue';

export default {
  name: "SitesView",
  components: {SitesComponent},
  data() {
    return {
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }
  },
  methods: {
  },
};
</script>
