<template>
  <div class="days">
    <div
      class="day"
      v-for="day in ultimos7Dias()"
      :key="day"
      :class="{
        ['hasposts']: (site.counter[diaKey(day)] || 0) > 0,
        ['error']: (site.counter[diaKey(day)] || 0) < 4,
        ['weekend']: isWeekend(day),
      }"
    >
      <strong>{{ diasDaSemanaAbreviado(day) }}</strong>
      <span>{{ site.counter[diaKey(day)] || 0 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SitePostsCount",
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isWeekend(data) {
      var today = data;
      var dayOfWeek = today.getDay();

      // Check if it's Saturday (6) or Sunday (0)
      return dayOfWeek === 0 || dayOfWeek === 6;
    },
    diasDaSemanaAbreviado(data) {
      // var opcoes = { weekday: "short", locale: "pt-BR" };
      // return data.toLocaleDateString("pt-BR", opcoes);
      return data.getDate().toString().padStart(2, "0");
    },
    diaKey(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      var curDate = `${date.getFullYear()}-${month}-${day}`;
      return curDate;
    },
    ultimos7Dias() {
      var hoje = new Date(); // Obtém a data atual
      var arrayUltimos7Dias = [];

      // Itera para obter as datas dos últimos 7 dias
      for (var i = 0; i < 7; i++) {
        var data = new Date(hoje); // Cria uma nova data com a data atual
        data.setDate(hoje.getDate() - i); // Subtrai o número de dias necessários
        arrayUltimos7Dias.push(data); // Adiciona a data ao array
      }

      return arrayUltimos7Dias.reverse(); // Retorna o array de datas
    },
  },
};
</script>

<style></style>
