import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AccountView from '@/views/AccountView.vue'
import TermosView from '@/views/TermosView.vue'
import PoliticasView from '@/views/PoliticasView.vue'
import HistoryView from '@/views/HistoryView.vue'
import AddSiteView from '@/views/AddSiteView.vue'
import SearchView from '@/views/SearchView.vue'
import SitesView from '@/views/SitesView.vue'
import PostarView from '@/views/PostarView.vue'
import StartView from '@/views/StartView.vue'
import WriteView from '@/views/WriteView.vue'
import CheckoutView from '@/views/CheckoutView.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: StartView,
  },
  {
    path: '/termos-de-uso',
    name: 'termos',
    component: TermosView
  },
  {
    path: '/politicas-de-privacidade',
    name: 'politicas',
    component: PoliticasView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/start',
    name: 'start',
    component: StartView,
    meta: { private: true },
  },
  {
    path: '/sites',
    name: 'sites',
    component: SitesView,
    meta: { private: true },
  },
  {
    path: '/postar',
    name: 'postar',
    component: PostarView,
    meta: { private: true },
  },
  {
    path: '/add-site',
    name: 'add-site',
    component: AddSiteView,
    meta: { private: true },
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta: { private: true },
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView,
    meta: { private: true },
  },
  {
    path: '/write',
    name: 'write',
    component: WriteView,
    meta: { private: true },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    meta: { private: true },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: { private: true },
  },
]

const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(!localStorage.user && to.meta.private) {
    return next({ name: 'home' })
  }
  next()
})

export default router
