<template>
  <div class="w-full">
    <div class="bg-slate-200 min-h-screen max-w-[100vw]">
      <sidebar-component v-if="$route.name !== 'home'"></sidebar-component>
      <router-view class="w-full" />
    </div>
    <footer class="bg-slate-300 text-center text-black py-4">
      Desenvolvido por <a href="https://ersolucoesweb.com.br" target="_blank"><strong>ER Soluções Web LTDA</strong> - CNPJ 33.480.243-0001/23</a>
    </footer>
  </div>
</template>
<script>
import DownloadComponent from "@/components/DownloadComponent.vue";
import SidebarComponent from "@/components/SidebarComponent.vue";
export default {
  components: { SidebarComponent, DownloadComponent },
  data() {
    return {
      ws: null
    };
  },
  watch: {
    $route: function () {
      if (localStorage.user) {
        if (!this.$monitor) {
          this.$start_monitor();
        }
        this.$axios
          .get("/me")
          .then((res) => {
            this.$store.commit("setMe", res.data);
            if(!this.ws) this.connectWS(res);
            this.$axios
              .get("/sync")
              .then((res) => this.$store.commit("setConfigs", res.data || {}))
              .catch(console.log);
          })
          .catch(() => {
            delete localStorage.user;
            location.reload();
          });
      }
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  mounted() {
    window.addEventListener("resize", () =>
      this.$store.commit("windowWidth", window.innerWidth)
    );
    window.addEventListener("loading:start", () => {
      document.body.classList.add("loading");
    });
    window.addEventListener("loading:stop", () => {
      document.body.classList.remove("loading");
    });
  },
  methods: {
    connectWS(res) {      
      this.ws = new WebSocket(
        "wss://ws.iaxpress.app?ch=" + res.data._id.$oid
      );
      this.ws.onmessage = (e) => {
        let data = JSON.parse(e.data);
        let event = data.event
        let message = data.body
        let customEvent = new Event(event)
        customEvent.message = message;
        window.dispatchEvent(customEvent);
      };
      this.ws.onclose = () => {
        this.connectWS(res);
      };
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}
body.loading::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 9999;
  cursor: wait;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 3s ease infinite;

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
