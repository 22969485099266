import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const wordpress = (app) => {

      let u = new URL(app.fontePosts);        
      let slug = u.pathname
        .split("/")
        .filter((n) => n)
        .pop();
    let _url = `https://${u.hostname}/wp-json/wp/v2/posts/?per_page=20&_fields=id,title,content,date,link,_links.wp:featuredmedia,_embedded.wp:featuredmedia&_embed`;
    if (app.remote_search) {
        _url += `&search=${app.remote_search}`;
    }
    if (app.remote_cat) {
        _url += `&categories=${app.remote_cat}`;
    }
    if (app.remote_date_search) {
        let search_date = new Date(app.remote_date_search + " 00:00:00");
        let start = `${search_date.getFullYear()}-${(search_date.getMonth() + 1)
            .toString()
            .padStart(2, 0)}-${search_date
                .getDate()
                .toString()
                .padStart(2, 0)}T00:00:00.000Z`.toString();
        let end = `${search_date.getFullYear()}-${(search_date.getMonth() + 1)
            .toString()
            .padStart(2, 0)}-${search_date
                .getDate()
                .toString()
                .padStart(2, 0)}T23:59:59.000Z`.toString();
        _url += `&after=${start}&before=${end}`;
    }
    if (slug) {
        _url += `&slug=${slug}`;
    }
    console.log(_url);
    let url = encodeURIComponent(_url);
    const id = app.$toast.loading("Buscando posts...", {
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
    });

    app.$axios
        .get(`${app.proxyUrl}/?url=${url}`)
        .then((res) => {
            app.posts = res.data;
            app.$toast.update(id, {
                render: res.data.length
                    ? `Posts carregados`
                    : "Nenhum post encontrado",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: res.data.length ? "success" : "error",
                isLoading: false,
            });
        })
}

export default {
    install: (app) => {
        app.config.globalProperties.$wordpressSearch = app => wordpress(app);
    }
};