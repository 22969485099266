<template>
  <div class="cols p-4">
    <div class="w-full">
      <data-navigation></data-navigation>
      <div
        v-if="history"
        class="p-4 rounded-md bg-white w-full flex flex-col gap-2"
      >
        <h1>Histórico de posts</h1>
        <div
          class="item flex flex-col lg:flex-row gap-2 justify-between lg:items-center text-[14px] py-1 px-3 rounded-sm border-l-4"
          v-for="item in history.items"
          :class="{
            ['border-l-blue-400 text-black']: item.processed == 1,
            ['bg-slate-300']: item.processed == 0,
          }"
          :key="item"
        >
          <div class="text-left lg:w-4/6 w-full">
            <div
              class="font-bold flex items-center gap-2"
              v-if="item.generated"
            >
              <a class="link" :href="getPostUrl(item)" target="_blank">
                <span v-html="item.generated.title"></span>
              </a>
            </div>
            <div
              class="text-left w-full text-[11px] flex gap-2 lg:text-[14px] justify-start"
            >
              <div v-html="getDomain(item.site_url)"></div>
              <div v-html="item.created_at" class="whitespace-nowrap"></div>
            </div>
            <div class="flex items-center gap-2">
              <a :href="item.fonte" target="_blank">
                <span v-html="item.title"></span>
              </a>
            </div>
          </div>

          <div v-if="!item.generated && item.error" class="text-left lg:w-2/6 w-full">
            <h3 class="text-red-500 w-full text-center">Houve um erro ao criar o post</h3>
            <div class="text-left w-full flex gap-1 text-[0.9em]">
              <button type="button" @click="resendToQueue(item._id.$oid)" class="bg-yellow-400 w-full text-center p-1 border">
                {{ resending == item._id.$oid ? 'Reenviando, aguarde...' : 'Tentar novamente' }}
              </button>
              <button type="button" @click="deleteFromHistory(item._id.$oid)" class="bg-black text-white w-full text-center p-1 border">
                {{ deleting == item._id.$oid ? 'Removendo, aguarde...' : 'Remover do histórico' }}
              </button>
            </div>
          </div>
          <div
            class="text-left lg:w-2/6 w-full flex gap-1 text-[0.9em]"
            v-if="item.generated && item.generated.diff"
          >
            <div
              class="flex flex-col w-full text-center p-1 border"
              v-if="item.generated.diff.title"
              :class="{ [diffColor(item.generated.diff.title)]: 1 }"
            >
              <span>Título gerado</span>
              <span class="px-2 mt-1 rounded-sm text-bold"
                >{{ item.generated.diff.title }}%</span
              >
              <span class="mt-1 whitespace-nowrap text-[11px]"
                >diferente do original</span
              >
            </div>
            <div
              class="flex flex-col w-full text-center p-1 border"
              v-if="item.generated.diff.content"
              :class="{ [diffColor(item.generated.diff.content)]: 1 }"
            >
              <span>Conteúdo gerado</span>
              <span class="px-2 mt-1 rounded-sm text-bold"
                >{{ item.generated.diff.content }}%</span
              >
              <span class="mt-1 whitespace-nowrap text-[11px]"
                >diferente do original</span
              >
            </div>
          </div>
        </div>
        <hr class="my-2" />
        <pagination-component
          @update:currentPage="changePage"
          :totalPages="pages"
          :currentPage="currentPage + 1"
        ></pagination-component>
      </div>
    </div>
  </div>
</template>

<script>
import DataNavigation from "../components/DataNavigation.vue";
// @ is an alias to /src
import PaginationComponent from "../components/PaginationComponent.vue";

export default {
  name: "HistoryView",
  components: {
    PaginationComponent,
    DataNavigation,
  },
  data() {
    return {
      history: false,
      currentPage: 0,
      resending: false,
      deleteing: false,
      reloading: false
    };
  },
  watch: {
    currentPage() {
      this.getHistory();
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }
    this.getHistory();
    window.addEventListener("history:reload", this.getHistory);
  },
  computed: {
    pages() {
      return this.history ? Math.round(this.history.total / 50) : 0;
    },
    configs() {
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    gtag() {
      return window.gtag;
    },
  },
  methods: {
    resendToQueue(id) {
      this.resending = id;
      this.$axios
        .post(`/resend/${id}`)
        .then(() => {
          this.resending = false;
        }).catch(() => {
          this.resending = false;
        });
    },
    deleteFromHistory(id) {
      this.deleting = id;
      this.$axios
        .delete(`/history/${id}`)
        .then(() => {
          this.deleting = false;
          this.getHistory()
        }).catch(() => {
          this.deleting = false;
        });
    },
    changePage(page) {
      this.currentPage = page - 1;
    },
    getPostUrl(item) {
      if (!item.generated) {
        return;
      }
      if (item.generated.response) {
        if (item.generated.response.data) {
          return item.generated.response.data.url;
        }
      }
      let site = new URL(item.site_url);
      return `${site.origin}?s=${encodeURIComponent(
        item.generated.title
      ).replace(/%20/g, "+")}`;
    },
    diffColor(p) {
      const percent = parseFloat(p);
      if (percent < 10) {
        return "bg-red-500 text-white";
      }
      if (percent < 30) {
        return "bg-yellow-500 text-white";
      }
      if (percent < 60) {
        return "bg-blue-500 text-white";
      }
      return "bg-green-500 text-white";
    },
    getHistory() {
      if(this.reloading) {
        return;
      }
      this.reloading = true;
      this.$axios
        .get("/history", { params: { page: this.currentPage } })
        .then((res) => {
          this.history = res.data
          this.reloading = false;
        }).catch(() => {
          this.reloading = false;
        });
    },
    getDomain(url) {
      try {
        return new URL(url).hostname;
      } catch {
        return "----";
      }
    },
  },
};
</script>

<style scoped>
a.link:visited {
  color: red;
}
</style>
