import { use } from 'react'
import { createStore } from 'vuex'

export default createStore({
  state: {
    me: false,
    configs: {},
    sites: {},
    windowWidth: 0,
    useSite: '',
    selectedSites: []
  },
  getters: {
  },
  mutations: {
    useSite(state, payload) {
      state.useSite = payload
    },
    setMe(state, payload) {
      state.me = payload
    },
    setConfigs(state, payload) {
      state.configs = payload
    },
    setCurrentSite(state, payload) {
      state.configs.currentSite = payload
    },
    windowWidth(state, payload) {
      state.windowWidth = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
