<template>
    <div class="flex justify-center w-full lg:gap-4 items-center mb-4">
        <button class="py-5 px-2 lg:px-4 text-white bg-green-500 lg:rounded-md" @click="previousWeek">&lt;</button>
        <div class="flex w-full lg:gap-4 items-center justify-center">
            <span v-for="(day, index) in days" :key="index" :class="{ today: isToday(day), weekend: isWeekend(day), haspost: (counts[formatDate(day, 'yyyymmdd')] || 0) }"
                class="bg-white w-full lg:w-[70px] lg:rounded-md leading-4 gap-0 flex flex-col items-center justify-center">
                <span class="bg-blue-500 text-[12px] text-white px-2 lg:px-4 w-full text-center pt-1 lg:rounded-t year">
                    {{ formatDate(day, 'yyyy') }}
                </span>
                <span class="bg-blue-500 font-bold text-[12px] text-white px-2 lg:px-4 w-full text-center day">
                    {{ formatDate(day, 'dd') }}
                </span>
                <span class="bg-blue-500 text-[11px] uppercase text-white px-2 lg:px-4 w-full text-center pb-1 month">
                    {{ formatDate(day, 'MMM') }}
                </span>
                <span class="text-[11px] py-1 w-full lg:rounded-b text-center counter">{{  counts[formatDate(day, 'yyyymmdd')] || 0 }}</span>
            </span>
        </div>
        <button @click="nextWeek" class="py-5 px-2 lg:px-4 text-white bg-green-500 lg:rounded-md">
            &gt;
        </button>
    </div>
</template>

<script>
export default {
    name: 'DataNavigation',
    data() {
        return {
            days: [],
            today: new Date(),
            counts: {}
        };
    },
    created() {
        this.generateDays(this.today);
    },
    methods: {
        generateDays(endDate) {
            const daysArray = [];
            for (let i = 6; i >= 0; i--) {
                const date = new Date(endDate);
                date.setDate(endDate.getDate() - i);
                daysArray.push(date);
            }
            this.days = daysArray;
            this.countPosts(daysArray);
        },
        formatDate(date, format) {
            const options = {
                dd: { day: '2-digit' },
                MMM: { month: 'short' },
                yyyy: { year: 'numeric' }
            };
            
            if (format === 'yyyymmdd') {
                // Formatação manual para garantir o separador '-'
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa de 0
                const day = String(date.getDate()).padStart(2, '0');

                return `${year}-${month}-${day}`;
            }
            return date.toLocaleDateString('pt-BR', options[format]);
        },
        isToday(date) {
            const today = new Date();
            return date.toDateString() === today.toDateString();
        },
        isWeekend(date) {
            return date.getDay() === 0 || date.getDay() === 6;
        },
        previousWeek() {
            const newEndDate = new Date(this.days[0]);
            newEndDate.setDate(newEndDate.getDate() - 1);
            this.generateDays(newEndDate);
        },
        nextWeek() {
            const newEndDate = new Date(this.days[6]);
            newEndDate.setDate(newEndDate.getDate() + 7);
            this.generateDays(newEndDate);
        },
        async countPosts(daysArray) {
            try {
                let dates = daysArray.map(date => date.toISOString().split('T')[0]);
                const response = await this.$axios.get('/days', { params: { days: dates } })
                    .then(response => this.counts = response.data);
                console.log('Days sent to API:', response.data);
            } catch (error) {
                console.error('Error sending days to API:', error);
            }
        }
    }
};
</script>

<style scoped>
.weekend {
    @apply opacity-50;
}
.haspost {
    @apply bg-green-500 text-white;
}
</style>
