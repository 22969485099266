<template>
  <div class="">
    <div class="cols p-4">
      <div class="bg-white rounded-lg w-full p-4">
        <h1 class="text-[14px] text-black">{{ queue.length}} Posts selecionados para postar</h1>
        <div class="w-full">
          <template v-for="(post, index) in queue" :key="index">
            <div
              class="flex gap-4 text-[12px] items-center justify-between w-full pt-2 my-2 border-t-2 mb-0"
            >
              <span v-html="post.title"></span>
              <div class="flex items-center gap-4">
                <a :href="post.url" target="_blank" class="btn success">
                  ver
                </a>
                <button
                  @click="removeFromList(post)"
                  class="btn danger"
                  id="sync"
                >
                  X
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="bg-white rounded-lg w-full p-4">
        <div class="w-full" v-if="step == 1">
          <h1 class="text-[14px] text-black">Selecione os sites para postar</h1>
          <template v-for="(site, index) in sites" :key="index">
            <div
              class="flex gap-4 text-[12px] items-center justify-between w-full py-2 my-2 border-b-2"
            >
              <label class="flex items-center gap-2 cursor-pointer border-l-4 pl-2" :style="`border-color: ${site.color}`">
                <input
                  type="checkbox"
                  :id="site._id.$oid"
                  :value="site._id.$oid"
                  v-model="selectedSites"
                />
                <span v-html="site.url" class="text-[14px]"></span>
              </label>
            </div>
          </template>
          <button
            @click="step = 2"
            class="w-full py-2 px-4 bg-yellow-500 text-white rounded-lg mt-4"
            v-if="selectedSites.length"
          >
            Próximo passo
          </button>
        </div>
        <div class="flex justify-between items-start gap-6">
          <div v-if="step == 2" class="w-full">
            <label class="block whitespace-nowrap">
              <input type="checkbox" v-model="form.usarOriginal" />
              Usar texto original
            </label>
            <p class="text-[11px] mb-2 mt-2">
              Não reescrever texto usando IA, postar o texto original da fonte
            </p>
            <div class="flex gap-4">
            <button
              @click="step = 1"
              class="w-full py-2 px-4 bg-slate-300 text-white rounded-lg mt-4"
            >
              Anterior
            </button>
            <button
              @click="step = 3"
              class="w-full py-2 px-4 bg-yellow-500 text-white rounded-lg mt-4"
              v-if="selectedSites.length"
            >
              Próximo passo
            </button>
          </div>
          </div>
          <div v-if="step == 3" class="w-full">
            <label class="block whitespace-nowrap font-bold mb-2"
              >Data para os posts</label
            >
            <p class="text-[11px] mb-2">
              Data para a publicação do post. Data futura fará o post ficar como
              agendado.
            </p>
            <input
              type="datetime-local"
              v-model="form.date"
              class="w-full py-2 px-4 bg-gray-100 rounded-lg"
            />
            <div class="flex gap-4">
            <button
              @click="step = 2"
              class="w-full py-2 px-4 bg-slate-300 text-white rounded-lg mt-4"
            >
              Anterior
            </button>
            <button
              @click="step = 4"
              class="w-full py-2 px-4 bg-yellow-500 text-white rounded-lg mt-4"
              v-if="selectedSites.length"
            >
              Próximo passo
            </button>
          </div>
          </div>
          <div v-if="step == 4" class="w-full">
            <label class="block whitespace-nowrap font-bold mb-2"
              >Legenda das imagens</label
            >
            <p class="text-[11px] mb-2">
              Legenda para ser colocada abaixo das imagens no WordPress
            </p>
            <input
              type="text"
              v-model="form.caption"
              class="w-full py-2 px-4 bg-gray-100 rounded-lg"
            />
            <div class="flex gap-4">
            <button
              @click="step = 3"
              class="w-full py-2 px-4 bg-slate-300 text-white rounded-lg mt-4"
            >
              Anterior
            </button>
            <button
              @click="step = 5"
              class="w-full py-2 px-4 bg-yellow-500 text-white rounded-lg mt-4"
              v-if="selectedSites.length"
            >
              Próximo passo
            </button>
          </div>
          </div>
        </div>
        <div v-if="step == 5" class="w-full">
          <h2 class="text-[18px] text-black mb-4">Confirme os dados</h2>
          <label class="font-bold">Sites para postar</label>
          <template v-for="(site, index) in sites" :key="index">
            <div
              class="flex gap-4 text-[12px] items-center justify-between w-full py-2 my-2 border-b-2"
              >
              <label class="flex items-center gap-2 cursor-pointer border-l-4 pl-2" :style="`border-color: ${site.color}`">
                <input
                type="checkbox"
                :id="site._id.$oid"
                :value="site._id.$oid"
                v-model="selectedSites"
                />
                <span v-html="site.url" class="text-[14px]"></span>
              </label>
            </div>
          </template>
          <div class="flex flex-col gap-4">
            <div class="flex justify-start gap-2"><strong>Postar original?</strong> <span>{{ form.usarOriginal ? 'Sim' : 'Não' }}</span></div>
            <div class="flex justify-start gap-2"><strong>Data para posts:</strong> <span>{{ form.date.replace('T', ' ') }}</span></div>
            <div class="flex justify-start gap-2"><strong>Legenda das imagens:</strong> <span>{{ form.caption }}</span></div>
          </div>
            <div class="flex gap-4">
            <button
              @click="step = 4"
              class="w-full py-2 px-4 bg-slate-300 text-white rounded-lg mt-4"
            >
              Anterior
            </button>
        <button
          @click="postar"
          v-if="selectedSites.length"
          class="w-full py-2 px-4 bg-green-500 text-white rounded-lg mt-4"
        >
          Postar nos sites
        </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostarView",
  components: {},
  data() {
    return {
      step: 1,
      queue: [],
      sites: [],
      selectedSites: [],
      form: {
        usarOriginal: false,
        date: "",
        caption: "",
      },
    };
  },
  watch: {
    step() {
      localStorage.step = this.step;
    },
    'form.usarOriginal'() {
      if(this.form.usarOriginal) {
        localStorage.usarOriginal = this.form.usarOriginal;
      } else {
        localStorage.removeItem('usarOriginal');
      }
    },
    'form.date'() {
      localStorage.date = this.form.date;
    },
    'form.caption'() {
      localStorage.caption = this.form.caption;
    },
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }
    this.$axios.get("queue").then((response) => {
      this.queue = response.data;
    }).catch(console.log);
    this.$axios.get("sites").then((response) => {
      this.sites = response.data;
    }).catch(console.log);
    if(this.$route.query.single) {
      this.form.usarOriginal = true;
    }
  },
  methods: {
    postar() {
      this.$axios
        .post("postar", { sites: this.selectedSites, ...this.form })
        .then(() => {
          this.$router.push("/history");
        }).catch(console.log);
    },
    removeFromList(_post) {
      let index = this.queue.indexOf(_post);
      this.queue.splice(index, 1);
      this.$axios.delete(`queue/${_post._id.$oid}`);
    },
  },
};
</script>
